// Variable overrides
// Font Variables
@import url('https://fonts.googleapis.com/css?family=Karla:200,300,400,500,700&display=swap');
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    6:($spacer * 6),
    7:($spacer * 7),
    8:($spacer * 8),
    9:($spacer * 9),
    10:($spacer * 10),
    11:($spacer * 11),
    12:($spacer * 12),
  ),
  $spacers
);

$primary: #000;
$success: #4aa76f;
$secondary: #333;
$warning: #e39647;
$danger: #a3490f;
$light: #fff;
$dark: #000;
$enable-responsive-font-sizes:true;
$info: #7EBDC5;
$light-bone: #FCFAEE;
$good-red: #E0674F;
$theme-colors: (
  "primary":    $primary,
  "success":    $success,
  "secondary":  $secondary,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "purple":     #353974,
  "light-bone": $light-bone,
  "good-red": $good-red,
);

$defaultFont: 'Karla', sans-serif;
$font-family-sans-serif: $defaultFont !default;
$font-size-base: 1rem !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$line-height-base: 1.7;
$line-heights: (
  10: 10 * $line-height-base,
  15: 15 * $line-height-base,
  20: 20 * $line-height-base,
  25: 25 * $line-height-base
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px
) !default;

