@import "./variables";

// Here you can add other styles
.btn {
  @extend .rounded-pill;
  @extend .shadow-none;
}

.card {
  @extend .rounded-0;
  @extend .shadow-none;
  @extend .border-dark;
}

.c-app {
  color: $dark;
  background-color: $light-bone;
}

.c-sidebar {
  @extend .border-right;
  @extend .bg-light;
  @extend .shadow-none;
  border-color: $dark !important;
}

.c-sidebar a {
  color: $dark !important;
}

.c-sidebar svg {
  color: $dark !important;
}

.c-sidebar svg:hover {
  @extend .text-light;
}

.c-sidebar a:hover {
  color: $light !important;
}

.c-header {
  @extend .shadow-none;
  @extend .border-bottom;
  border-color: $dark !important;
}

.form-control {
  @extend .rounded-0;
  @extend .py-4;
  @extend .border-dark;
}

.modal-content {
  @extend .rounded-0;
  @extend .border-dark;
}

div[class*="-control"] {
  @extend .border-dark;
  @extend .rounded-0;
  @extend .py-1;
}

div .no-border {
  border: 0px !important;
}

@media print {
  .hide-me-in-print {
    display: none;
  }
  .c-header-brand {
    display: none;
  }
}

@media screen {
  .hide-me-on-screen {
    display: none;
  }
}

.Col--ItemId,
.Col--TransactionId {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
